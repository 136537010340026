.imgWidth {
    height: 75px;
    width : 200px;
}

.MuiListItemText-primary {
    font-weight: 500 !important;
}

body .flag-select {
    margin-left   : 30px;
    padding-bottom: 0px;
    display       : flex;
    align-items   : center;
    color         : #fff;

    img {
        top: 0;
    }
}

body .headerMobile .flag-select {
    color : #1c313a;
    margin: 10px;
}

@media only screen and (max-width: 1024px) {
    .imgWidth {
        height: 45px;
        width : 130px;
    }
}