/*!

=========================================================
* Material Kit PRO React - v1.8.0 based on Material Kit PRO v2.0.2 (Bootstrap 4.0.0 Final Edition)
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


// Core Components
@import "core/variables";
@import "core/mixins";
@import "core/fileupload";

// Core Plugins
@import "core/misc";

// @import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-image-gallery";
@import "plugins/plugin-react-slick";
@import "plugins/plugin-react-tagsinput";

@import "customVariables/variables";

@font-face {
  font-family: Raleway-Medium;
  src        : url(../fonts/Raleway-Medium.ttf);
}

@font-face {
  font-family: Raleway-SemiBold;
  src        : url(../fonts/Raleway-SemiBold.ttf);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Raleway-SemiBold !important;

}


.btn-spinner {
  border       : 3px solid $whiteColor;
  height       : 20px;
  width        : 20px;
  border-radius: 50%;
  border-top   : 5px solid transparent;
  animation    : load-animate infinite linear 1.2s;
}

.btn-spinner-dark {
  border       : 3px solid $spinnerDarkColor;
  height       : 15px;
  width        : 15px;
  border-radius: 50%;
  border-top   : 5px solid transparent;
  animation    : load-animate infinite linear 1.2s;
}

@keyframes load-animate {
  0% {
    -webkit-transform: rotate(0deg);
    transform        : rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform        : rotate(180deg);
    opacity          : 0.35;
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform        : rotate(360deg);
  }
}


.react-pdf__Page__canvas {
  border       : 1px solid $blackColor;
  margin-bottom: 50px;
}

.MuiTimelineItem-missingOppositeContent::before {
  display: none;
}

.section--timeline {
  margin-top : 30px;
  margin-left: 15px;
}

.iconMuiTable {
  margin-bottom: 0;

  svg {
    color : $secondaryMainColor;
    cursor: pointer;

    &:hover {
      color: $secondaryDarkColor
    }
  }
}

.tracking-order--title {
  color      : $secondaryMainColor;
  margin-left: 30px;
  margin-top : 30px;
  font-size  : 1.5rem;
  display    : flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-left: 50px;
  }

  svg {
    cursor: pointer;
  }
}

.section--user--setting {
  margin-top  : 30px;
  margin-left : 30px;
  margin-right: 30px;

  p {
    text-transform: capitalize;
    font-size     : 1.2rem;
    color         : $bodyTextColor;
    display       : flex;
    align-items   : center;
    font-weight   : 500;
    padding-bottom: 20px;
    padding-top   : 20px;
    border-bottom : 1px solid $borderGrayColor;
    cursor        : pointer;

    svg {
      margin-right: 10px
    }
  }
}

.profile-pic {
  border-radius        : 50%;
  height               : 250px;
  width                : 250px;
  background-size      : cover;
  background-position  : center;
  background-blend-mode: multiply;
  vertical-align       : middle;
  text-align           : center;
  color                : transparent;
  transition           : all .3s ease;
  text-decoration      : none;
  cursor               : pointer;

  &:hover {
    background-color: rgba(0, 0, 0, .5);
    z-index         : 10000;
    color           : whiteColor;
    transition      : all .3s ease;
    text-decoration : none;
  }

  p {
    display      : inline-block;
    margin-bottom: 0px;
    margin-top   : 0px !important;
  }
}



.hover-image {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
  height         : 100%;

  svg {
    margin-bottom: 8px
  }
}

.profile-upload-form {
  margin: 50px 30px;

  input[type="file"] {
    display: none;
    cursor : pointer;
  }
}

.profile-upload-form-custom {
  input[type="file"] {
    display: none;
    cursor : pointer;
  }

  .profile-pic {
    height: 200px;
    width : 200px;
  }
}

.buttons-section {
  margin-bottom: 40px;
  text-align   : center;

  button {
    margin-right: 5px;
    margin-left : 5px;
  }
}

.close--icon--profile--image {
  position: absolute;
  right   : 15px;
  top     : 15px;
  cursor  : pointer;
  color   : $primaryMainColor;

  &:hover {
    color: $primaryHoverColor;
  }

}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: $secondaryMainColor !important;
}

.MuiPickersModal-dialogRoot {
  .MuiDialogActions-spacing {
    button {
      color: $secondaryMainColor !important;
    }
  }
}

.MuiPickersYearSelection-container {
  .MuiTypography-colorPrimary {
    color: $secondaryMainColor !important;
  }
}

.chat--loader--div {
  width         : 400px;
  height        : 90vh;
  display       : flex;
  padding-top   : 50px;
  flex-direction: column;
  align-items   : center;

  @media (max-width: 768px) {
    width: 100vw;
  }

  .btn-spinner-dark {
    height: 30px;
    width : 30px;
  }

  p {
    margin-top: 20px;
  }
}

.chat-window--title {
  color        : $secondaryMainColor;
  margin-left  : 20px;
  margin-top   : 20px;
  margin-bottom: 20px;
  font-size    : 1.5rem;
  display      : flex;
  align-items  : center;

  svg {
    cursor: pointer;
  }
}

.k-chat {
  height: 90vh !important;

  .k-bubble {
    background-color: $lightGrayColor !important;
    border-color    : $lightGrayColor !important;
  }

  .k-alt {

    .k-bubble {
      background-color: $secondaryMainColor !important;
      border-color    : $secondaryMainColor !important;
    }
  }
}

.k-message-box {
  .k-button {
    svg {
      fill: $secondaryDarkColor !important;
    }
  }
}

.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer {
  background-color: $secondaryMainColor !important;
}

.MuiPickersClockPointer-thumb {
  border: 14px solid $secondaryMainColor !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid $secondaryMainColor !important;
}

.footer-form {
  .MuiInput-underline:after {
    border-bottom: 2px solid $whiteColor !important;
  }

  .MuiInput-underline:before {
    border-bottom: 2px solid $whiteColor !important;
  }

  .MuiInputBase-root {
    color: $whiteColor;
  }

  .MuiFormLabel-root {
    color: $whiteColor !important;
  }
}