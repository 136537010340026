$logoColor : #828381;

$primaryLightColor : #cfd8dc;
$primaryDarkColor  : #9ea7aa;
$primaryMainColor  : #455a64;
$primaryHoverColor: #2b424e;

$secondaryMainColor : #0097a7;
$secondaryDarkColor :#006978;

$whiteColor: #fff;
$blackColor: #000;
$spinnerDarkColor: #0000007a;

$lightGrayColor: #e0e0e0;
$borderGrayColor: #ddd;
$bodyTextColor: #757575; 

